<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngFor="let item of menuItems">
        <li>
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>

          <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                {{ subitem.label | translate}}
              </a>
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                    class="side-nav-link-ref">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>

      <!-- <li>
        <a class="side-nav-link-ref" routerLinkActive="active" (click)="assignApiModal(assignApi)" style="cursor: pointer;">
          <i class="bx bx-basket"></i>
          <span> TopUp</span>
        </a>
      </li> -->

      <li>
        <a class="side-nav-link-ref" routerLinkActive="active" style="cursor: pointer;" (click)="logout()">
          <i class="bx bx-log-out-circle"></i>
          <span> Logout</span>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->

<ng-template #assignApi role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Assign API</h5>
    <button type="button" class="btn-close" aria-hidden="true"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col-12">
          <label>Select API Type/API Service</label>
          <ng-select [items]="accounts" bindLabel="name" bindValue="name" name="selectedAccounts"
            [groupBy]="groupByFn" [groupValue]="groupValueFn" [multiple]="true" [closeOnSelect]="false"
            #selectedAccounts="ngModel" [(ngModel)]="obj.selectedAccounts" (add)="onSelectChange($event)"
            (remove)="onRemoveItem($event)">
            <ng-template ng-optgroup-tmp let-item="item">
              <span style="vertical-align: middle; font-weight: bolder; color: #000;">Category: {{item.name}}</span>
              <span class="ml-1 badge badge-secondary">{{item.total}}</span>
            </ng-template>
          </ng-select>
        </div>

        <div class="col-12 mt-4">
          <table class="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">API Service</th>
                <th scope="col">API Units</th>
                <th scope="col">Amount</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let item of requestedApis, index as i">
                <td>
                  <div class="pt-2">
                    <h5 class="text-truncate font-size-12">{{item.name}}</h5>
                  </div>
                </td>
                <td>
                  <form>
                    <div class="row">
                      <div class="col-lg-12 mb-3">
                        <input type="text" class="form-control" id="formrow-firstname-input"
                          name="apiUnits{{i}}" #apiUnits="ngModel" [(ngModel)]="item.apiUnits"
                          (keyup)="calculateAmount()">
                      </div>
                    </div>
                  </form>
                </td>
                <td>
                  <div class="pt-2">#{{item.age * item.apiUnits}}</div>
                </td>
                <td><i class="bx bx-x font-size-18 pt-2" style="cursor: pointer;" (click)="removeItem(item)"></i></td>
              </tr>

              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-end">Total:</h6>
                </td>
                <td># {{totalAmount}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary">Cancel</button>
    <button class="btn btn-success">Assign API</button>
  </div>
</ng-template>
