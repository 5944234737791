import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 9,
    label: "Dashboard",
    icon: "bx-home",
    link: "/dashboard",
  },
  // {
  //   id: 7,
  //   isLayout: true,
  // },

  // {
  //   id: 36,
  //   label: "Order Management",
  //   icon: "bx-receipt",
  //   subItems: [
  //     {
  //       id: 37,
  //       label: "Pending Orders",
  //       link: "/orders/pending-orders",
  //       parentId: 36,
  //     },
  //     {
  //       id: 38,
  //       label: "Packaing Orders",
  //       link: "/orders/packaging-orders",
  //       parentId: 36,
  //     },
  //     {
  //       id: 38,
  //       label: "Shipping & Dispatch",
  //       link: "/orders/dispatch-orders",
  //       parentId: 36,
  //     },
  //     {
  //       id: 38,
  //       label: "Delivered Order",
  //       link: "/orders/delivery-orders",
  //       parentId: 36,
  //     },
  //     {
  //       id: 38,
  //       label: "Cancelled Order",
  //       link: "/orders/cancelled-orders",
  //       parentId: 36,
  //     },
  //   ],
  // },

  {
    id: 36,
    label: "Appointments",
    icon: "bx-book-open",
    link: "/appointment",
  },

  {
    id: 36,
    label: "Planning Information - ALL",
    icon: "bx-book-open",
    link: "/planning-information",
  },

  {
    id: 36,
    label: "Planning Information - PENDING",
    icon: "bx-book-open",
    link: "/planning-information/pending",
  },

  {
    id: 36,
    label: "Planning Information - CHARTING",
    icon: "bx-book-open",
    link: "/planning-information/charting",
  },

  {
    id: 36,
    label: "Planning Information - APPROVED",
    icon: "bx-book-open",
    link: "/planning-information/approved",
  },

  {
    id: 36,
    label: "Planning Information - INSPECTION",
    icon: "bx-book-open",
    link: "/planning-information/inspection",
  },

  {
    id: 36,
    label: "Planning Information - INSPECTED",
    icon: "bx-book-open",
    link: "/planning-information/inspected",
  },

  {
    id: 36,
    label: "Planning Information - COMPLETED",
    icon: "bx-book-open",
    link: "/planning-information/completed",
  },

  {
    id: 36,
    label: "Calendar (coming soon)",
    icon: "bx-calendar",
    link: "/dashboard",
  },

  {
    id: 36,
    label: "Payments (coming soon)",
    icon: "bx-money",
    link: "/dashboard",
  },

  {
    id: 36,
    label: "Customers",
    icon: "bx-user",
    link: "/customers",
  },

  {
    id: 36,
    label: "Documents",
    icon: "bx-file",
    link: "/documents",
  },

  {
    id: 36,
    label: "Staff",
    icon: "bx-user",
    link: "/staff",
  },

  {
    id: 36,
    label: "Settings",
    icon: "bx-cog",
    subItems: [
      {
        id: 37,
        label: "Category",
        link: "/settings/categories",
        parentId: 36,
      },
      {
        id: 38,
        label: "Professions",
        link: "/settings/professions",
        parentId: 36,
      },
      {
        id: 38,
        label: "Locations",
        link: "/settings/locations",
        parentId: 36,
      },
      {
        id: 38,
        label: "Services",
        link: "/settings/services",
        parentId: 36,
      },
    ],
  },

  {
    id: 36,
    label: "Support Requests (coming soon)",
    icon: "bx-chat",
    link: "/dashboard",
  },
];
